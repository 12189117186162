import { useEffect, useState } from 'react';
import { HamQuest } from '../../../App';

const GetState = (url, { callback = null, loading = null, finalCall = null } = {}) => {
    const [state, setState] = useState(null);

    const fetchData = (urlOverride) => (
        HamQuest.get(urlOverride ?? url)
            .then(res => {
                if (callback) {
                    res instanceof Array ?
                        res.forEach(e => callback(e))
                        : callback(res)
                }
                if (finalCall) {
                    res = finalCall(res)
                }
                setState(res)
                return res;
            })
            .catch(ex => {
                console.error(ex)
                setState(ex)
            })
    )

    useEffect(() => {
        if (!url) return;
        fetchData()
    }, [])

    return [
        state ?? loading,
        fetchData,
        setState,
    ]
}

export default GetState;
